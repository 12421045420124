import React, {useEffect, useState} from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import Ranking from "../components/Ranking";
import axios from "axios";
import './Tab4.css';

const Tab4: React.FC = () => {
  const fetchURL = "https://apidev.valais.cloud/res/all/5255b44b1ac4f1df35f03c6f09ab46f2";
  
  interface IPart {
    timestamp: number;
    nom: string;
    prenom: string;
    npa: string;
    categorie: string;
    genre: string;
    annee: number;
    temps: number;
  }

  const [items, setItems] = useState([{timestamp: 0,
                                      nom: "",
                                      prenom: "",
                                      npa: "",
                                      categorie: "",
                                      genre: "",
                                      annee: 0,
                                      temps: 0}] as IPart[]);
  
  useEffect(() => {
      //getItems().then(data => setItems(data));
      axios.get(fetchURL, )
            .then((data) => (setItems(data.data as IPart[])))
            .catch((error) => console.log("Error"))
            .then((data) => console.log("Then"));
      //console.log(items);
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Classement 2020</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Classement 2020</IonTitle>
          </IonToolbar>
        </IonHeader>
            <Ranking items={items} />
      </IonContent>
    </IonPage>
  );
};

export default Tab4;