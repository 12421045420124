import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

interface ICoor {
    label: string,
    name: string,
    location: { 
        lat: number,
        lng: number 
    }
}

export class Maps extends React.Component<{toastTxt: any, toast: any, mapStyles: any, defaultCenter: any, coor: ICoor []}> {
  render() {
    return (
        <LoadScript
            googleMapsApiKey='AIzaSyDU-YulqK-bK-EMIyzPou5KHSBDAIwWlv8'>
            <GoogleMap
                mapContainerStyle={this.props.mapStyles}
                zoom={13}
                center={this.props.defaultCenter}
            >
            {
                this.props.coor.map(item => {
                    return (
                        <Marker onClick={() => {this.props.toast(false); this.props.toastTxt(item.label); this.props.toast(true)}} key={item.name} position={item.location}/>
                    )
                })
            }
            </GoogleMap>
        </LoadScript>
    )
  }
};