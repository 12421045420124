import React from 'react'
import { IonList, IonLabel, IonItem } from '@ionic/react';

interface IPart {
    timestamp: number;
    nom: string;
    prenom: string;
    npa: string;
    categorie: string;
    genre: string;
    annee: number;
    temps: number;
}

class Ranking extends React.Component<{items: IPart[]}> {
    render() {
        return (
            <IonList>
                {this.props.items.map((item: IPart) => (
                    <IonItem key={item.timestamp}>
                        <IonLabel><h2>{item.nom} {item.prenom}</h2><p>{Intl.DateTimeFormat('fr-CH',{
                        month: "2-digit",
                        day: "2-digit",
                        year: "2-digit"
                    }).format(item.timestamp*1000)} - {item.categorie} - <strong>{("0" + Math.floor(item.temps / 3600)).slice(-2)}:{("0" + (Math.floor(Math.floor((item.temps%3600) / 60)))).slice(-2)}</strong></p></IonLabel></IonItem>
                    ))}
            </IonList>
        );
    }
}

export default Ranking;