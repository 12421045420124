import React, { useState } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonToast } from '@ionic/react';
import { Maps } from '../components/Maps';
import './Tab3.css';

const Tab3: React.FC = () => {
  const [showToast1, setShowToast1] = useState(false);
  const [toast1Txt, setToast1Txt] = useState('');

  const mapStyles = {        
    height: "100vh",
    width: "100%"};
  
  const defaultCenter = {
    lat: 46.17141, lng: 7.28741
  };
  
  const coor = [
    {
      label: "Départ et arrivée",
      name: "Départ/Arrivée",
      location: { 
        lat: 46.18188, 
        lng: 7.29032 
      },
    },
    {
      label: "Vélo, point intermédiaire",
      name: "PointIntermédiaireVélo",
      location: { 
        lat: 46.16163, 
        lng: 7.28353
      },
    }
  ];

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Carte</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Carte</IonTitle>
          </IonToolbar>
        </IonHeader>
          <Maps toastTxt={setToast1Txt} toast={setShowToast1} mapStyles={mapStyles} defaultCenter={defaultCenter} coor={coor} />
      </IonContent>
      <IonToast
        isOpen={showToast1}
        onDidDismiss={() => setShowToast1(false)}
        message={toast1Txt}
        duration={5000}
      />
    </IonPage>
  );
};

export default Tab3;
