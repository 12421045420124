import React from 'react';
import { IonGrid, IonRow, IonCol, IonImg } from '@ionic/react';

export class Status extends React.Component<{status: any}> {

    render() {
        let img1 = <IonImg src={"assets/icon/status/g1.png"} />
        let img2 = <IonImg src={"assets/icon/status/g2.png"} />
        let img3 = <IonImg src={"assets/icon/status/g3.png"} />
        let ren = <></>

        if ( this.props.status === '0' )
        {
           img1 = <IonImg src={"assets/icon/status/g1.png"} />
           img2 = <IonImg src={"assets/icon/status/g2.png"} />
           img3 = <IonImg src={"assets/icon/status/g3.png"} />
        }
        if ( this.props.status === '1' )
        {
           img1 = <IonImg src={"assets/icon/status/1.png"} />
           img2 = <IonImg src={"assets/icon/status/g2.png"} />
           img3 = <IonImg src={"assets/icon/status/g3.png"} />
        }
        if ( this.props.status === '2' )
        {
           img1 = <IonImg src={"assets/icon/status/1.png"} />
           img2 = <IonImg src={"assets/icon/status/2.png"} />
           img3 = <IonImg src={"assets/icon/status/g3.png"} />
        }
        if ( this.props.status === '3' )
        {
           img1 = <IonImg src={"assets/icon/status/1.png"} />
           img2 = <IonImg src={"assets/icon/status/2.png"} />
           img3 = <IonImg src={"assets/icon/status/3.png"} />
        }
        if (this.props.status !== 'd')
        {
          ren = <IonGrid>
            <IonRow>
              <IonCol>
                  {img1}
              </IonCol>
              <IonCol>
                  {img2}
              </IonCol>
              <IonCol>
                  {img3}
              </IonCol>
            </IonRow>
          </IonGrid>
        }

    return (
        <>
           {ren}
        </>
    )
  }
};

export default Status;